.cursor-pointer {
   cursor: pointer;
}

.partners-logo {
   width: 120px;
}


.btn-paid-in-cash {
   border-radius: 0.5rem;
   font-size: 0.7rem;
   transition: all 0.3s ease;
   max-width: 200px;
   padding: 0.3em 0.5rem;
   margin-bottom: 1rem;
}

@media (max-width: 767.98px) {
   .partners-logo {
      width: 100px;
   }

   .btn-paid-in-cash {
      font-size: 0.8rem;  
      padding: 0.3rem 0.8rem;  
      max-width: 100%;  
   }
}

@media (max-width: 400px) {
   .btn-paid-in-cash {
      font-size: 0.7rem;  
      padding: 0.1rem 0.4rem;  
      max-width: 100%; 
   }

   .cash-icon {
      font-size: 0.8rem;
   }
}