.carouselStyles {
  width: 40%;
  max-width: 340px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 600px) {
  .carouselStyles {
    width: 60%;
  }
}

@media screen and (max-width: 480px) {
  .carouselStyles {
    width: 100%;
  }
}
